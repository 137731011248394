import {BiSolidVideos} from 'react-icons/bi';

const routesConfig = [
  {
    id: 'FlightVideoRecordings',
    title: 'Flight Video Recordings',
    messageId: 'sidebar.flightVideoRecordings',
    type: 'group',
    children: [
      {
        id: 'flight-records',
        title: 'Recorded Flights',
        messageId: 'sidebar.app.dashboard.flightRecords',
        icon: <BiSolidVideos />,
        path: '/flightRecords',
      },
    ],
  },
];
export default routesConfig;
