import React from 'react';
import {Navigate} from 'react-router-dom';
import Error403 from '../../../modules/errorPages/Error403';
import {errorPagesConfigs} from './ErrorPagesRoutes';
import {dashboardConfig} from './DashboardsRoutes';
import {initialUrl} from '@crema/constants/AppConst';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  // routes: [...dashboardConfig],
  routes: [],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
};
const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Navigate to={initialUrl} />,
    },
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
    ...dashboardConfig,
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
