import React from 'react';

type AppAuthProviderProps = {
  children: React.ReactNode;
};

const AppAuthProvider = ({children}: AppAuthProviderProps) => {
  return <>{children}</>;
};

export default AppAuthProvider;
