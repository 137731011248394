import React from 'react';

const FlightRecords = React.lazy(
  () => import('../../../modules/dashboards/flightRecords'),
);

export const dashboardConfig = [
  {
    // permittedRole: RoutePermittedRole.User,
    path: '/flightRecords',
    element: <FlightRecords />,
  },
];
